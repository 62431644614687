
    import './styles.scoped.css';
    export default {
  "box": "awsui_box_18wu0_1ot31_172",
  "p-variant": "awsui_p-variant_18wu0_1ot31_172",
  "color-default": "awsui_color-default_18wu0_1ot31_172",
  "b-variant": "awsui_b-variant_18wu0_1ot31_172",
  "strong-variant": "awsui_strong-variant_18wu0_1ot31_172",
  "code-variant": "awsui_code-variant_18wu0_1ot31_172",
  "pre-variant": "awsui_pre-variant_18wu0_1ot31_172",
  "samp-variant": "awsui_samp-variant_18wu0_1ot31_172",
  "h1-variant": "awsui_h1-variant_18wu0_1ot31_176",
  "h2-variant": "awsui_h2-variant_18wu0_1ot31_176",
  "h3-variant": "awsui_h3-variant_18wu0_1ot31_176",
  "h4-variant": "awsui_h4-variant_18wu0_1ot31_176",
  "h5-variant": "awsui_h5-variant_18wu0_1ot31_176",
  "small-variant": "awsui_small-variant_18wu0_1ot31_180",
  "a-variant": "awsui_a-variant_18wu0_1ot31_184",
  "font-size-default": "awsui_font-size-default_18wu0_1ot31_188",
  "font-weight-default": "awsui_font-weight-default_18wu0_1ot31_228",
  "key-label-variant": "awsui_key-label-variant_18wu0_1ot31_270",
  "gen-ai-label-variant": "awsui_gen-ai-label-variant_18wu0_1ot31_277",
  "value-large-variant": "awsui_value-large-variant_18wu0_1ot31_284",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_1ot31_291",
  "color-inverted": "awsui_color-inverted_18wu0_1ot31_296",
  "color-text-label": "awsui_color-text-label_18wu0_1ot31_299",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_1ot31_302",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_1ot31_305",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_1ot31_308",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_1ot31_311",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_1ot31_314",
  "color-text-status-warning": "awsui_color-text-status-warning_18wu0_1ot31_317",
  "color-inherit": "awsui_color-inherit_18wu0_1ot31_320",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_1ot31_323",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_1ot31_328",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_1ot31_332",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_1ot31_336",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_1ot31_341",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_1ot31_346",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_1ot31_351",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_1ot31_356",
  "font-weight-light": "awsui_font-weight-light_18wu0_1ot31_361",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_1ot31_364",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_1ot31_367",
  "t-left": "awsui_t-left_18wu0_1ot31_374",
  "t-right": "awsui_t-right_18wu0_1ot31_378",
  "t-center": "awsui_t-center_18wu0_1ot31_382",
  "p-n": "awsui_p-n_18wu0_1ot31_399",
  "p-top-n": "awsui_p-top-n_18wu0_1ot31_404",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_1ot31_405",
  "p-right-n": "awsui_p-right-n_18wu0_1ot31_409",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_1ot31_410",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_1ot31_414",
  "p-left-n": "awsui_p-left-n_18wu0_1ot31_419",
  "p-xxxs": "awsui_p-xxxs_18wu0_1ot31_424",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_1ot31_429",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_1ot31_430",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_1ot31_434",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_1ot31_435",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_1ot31_439",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_1ot31_444",
  "p-xxs": "awsui_p-xxs_18wu0_1ot31_449",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_1ot31_454",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_1ot31_455",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_1ot31_459",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_1ot31_460",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_1ot31_464",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_1ot31_469",
  "p-xs": "awsui_p-xs_18wu0_1ot31_474",
  "p-top-xs": "awsui_p-top-xs_18wu0_1ot31_479",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_1ot31_480",
  "p-right-xs": "awsui_p-right-xs_18wu0_1ot31_484",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_1ot31_485",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_1ot31_489",
  "p-left-xs": "awsui_p-left-xs_18wu0_1ot31_494",
  "p-s": "awsui_p-s_18wu0_1ot31_499",
  "p-top-s": "awsui_p-top-s_18wu0_1ot31_504",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_1ot31_505",
  "p-right-s": "awsui_p-right-s_18wu0_1ot31_509",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_1ot31_510",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_1ot31_514",
  "p-left-s": "awsui_p-left-s_18wu0_1ot31_519",
  "p-m": "awsui_p-m_18wu0_1ot31_524",
  "p-top-m": "awsui_p-top-m_18wu0_1ot31_529",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_1ot31_530",
  "p-right-m": "awsui_p-right-m_18wu0_1ot31_534",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_1ot31_535",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_1ot31_539",
  "p-left-m": "awsui_p-left-m_18wu0_1ot31_544",
  "p-l": "awsui_p-l_18wu0_1ot31_419",
  "p-top-l": "awsui_p-top-l_18wu0_1ot31_554",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_1ot31_555",
  "p-right-l": "awsui_p-right-l_18wu0_1ot31_559",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_1ot31_560",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_1ot31_564",
  "p-left-l": "awsui_p-left-l_18wu0_1ot31_569",
  "p-xl": "awsui_p-xl_18wu0_1ot31_574",
  "p-top-xl": "awsui_p-top-xl_18wu0_1ot31_579",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_1ot31_580",
  "p-right-xl": "awsui_p-right-xl_18wu0_1ot31_584",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_1ot31_585",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_1ot31_589",
  "p-left-xl": "awsui_p-left-xl_18wu0_1ot31_594",
  "p-xxl": "awsui_p-xxl_18wu0_1ot31_599",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_1ot31_604",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_1ot31_605",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_1ot31_609",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_1ot31_610",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_1ot31_614",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_1ot31_619",
  "p-xxxl": "awsui_p-xxxl_18wu0_1ot31_624",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_1ot31_629",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_1ot31_630",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_1ot31_634",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_1ot31_635",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_1ot31_639",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_1ot31_644",
  "m-n": "awsui_m-n_18wu0_1ot31_649",
  "m-top-n": "awsui_m-top-n_18wu0_1ot31_654",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_1ot31_655",
  "m-right-n": "awsui_m-right-n_18wu0_1ot31_659",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_1ot31_660",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_1ot31_664",
  "m-left-n": "awsui_m-left-n_18wu0_1ot31_669",
  "m-xxxs": "awsui_m-xxxs_18wu0_1ot31_674",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_1ot31_679",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_1ot31_680",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_1ot31_684",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_1ot31_685",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_1ot31_689",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_1ot31_694",
  "m-xxs": "awsui_m-xxs_18wu0_1ot31_699",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_1ot31_704",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_1ot31_705",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_1ot31_709",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_1ot31_710",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_1ot31_714",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_1ot31_719",
  "m-xs": "awsui_m-xs_18wu0_1ot31_724",
  "m-top-xs": "awsui_m-top-xs_18wu0_1ot31_729",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_1ot31_730",
  "m-right-xs": "awsui_m-right-xs_18wu0_1ot31_734",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_1ot31_735",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_1ot31_739",
  "m-left-xs": "awsui_m-left-xs_18wu0_1ot31_744",
  "m-s": "awsui_m-s_18wu0_1ot31_749",
  "m-top-s": "awsui_m-top-s_18wu0_1ot31_754",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_1ot31_755",
  "m-right-s": "awsui_m-right-s_18wu0_1ot31_759",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_1ot31_760",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_1ot31_764",
  "m-left-s": "awsui_m-left-s_18wu0_1ot31_769",
  "m-m": "awsui_m-m_18wu0_1ot31_774",
  "m-top-m": "awsui_m-top-m_18wu0_1ot31_779",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_1ot31_780",
  "m-right-m": "awsui_m-right-m_18wu0_1ot31_784",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_1ot31_785",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_1ot31_789",
  "m-left-m": "awsui_m-left-m_18wu0_1ot31_794",
  "m-l": "awsui_m-l_18wu0_1ot31_669",
  "m-top-l": "awsui_m-top-l_18wu0_1ot31_804",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_1ot31_805",
  "m-right-l": "awsui_m-right-l_18wu0_1ot31_809",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_1ot31_810",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_1ot31_814",
  "m-left-l": "awsui_m-left-l_18wu0_1ot31_819",
  "m-xl": "awsui_m-xl_18wu0_1ot31_824",
  "m-top-xl": "awsui_m-top-xl_18wu0_1ot31_829",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_1ot31_830",
  "m-right-xl": "awsui_m-right-xl_18wu0_1ot31_834",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_1ot31_835",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_1ot31_839",
  "m-left-xl": "awsui_m-left-xl_18wu0_1ot31_844",
  "m-xxl": "awsui_m-xxl_18wu0_1ot31_849",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_1ot31_854",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_1ot31_855",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_1ot31_859",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_1ot31_860",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_1ot31_864",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_1ot31_869",
  "m-xxxl": "awsui_m-xxxl_18wu0_1ot31_874",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_1ot31_879",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_1ot31_880",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_1ot31_884",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_1ot31_885",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_1ot31_889",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_1ot31_894",
  "d-block": "awsui_d-block_18wu0_1ot31_899",
  "d-inline": "awsui_d-inline_18wu0_1ot31_902",
  "d-inline-block": "awsui_d-inline-block_18wu0_1ot31_905",
  "d-none": "awsui_d-none_18wu0_1ot31_908",
  "f-left": "awsui_f-left_18wu0_1ot31_912",
  "f-right": "awsui_f-right_18wu0_1ot31_916",
  "root": "awsui_root_18wu0_1ot31_920"
};
  