
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_1hu00_153",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_1hu00_197",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_1hu00_202",
  "navigation": "awsui_navigation_7nfqu_1hu00_217",
  "tools": "awsui_tools_7nfqu_1hu00_218",
  "global-tools": "awsui_global-tools_7nfqu_1hu00_219",
  "tools-open": "awsui_tools-open_7nfqu_1hu00_257",
  "split-panel-side": "awsui_split-panel-side_7nfqu_1hu00_270",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_1hu00_277",
  "panel-hidden": "awsui_panel-hidden_7nfqu_1hu00_290",
  "toolbar-container": "awsui_toolbar-container_7nfqu_1hu00_300",
  "notifications-container": "awsui_notifications-container_7nfqu_1hu00_306",
  "notifications-background": "awsui_notifications-background_7nfqu_1hu00_310",
  "main-landmark": "awsui_main-landmark_7nfqu_1hu00_321",
  "main": "awsui_main_7nfqu_1hu00_321",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_1hu00_330",
  "content-header": "awsui_content-header_7nfqu_1hu00_340",
  "content": "awsui_content_7nfqu_1hu00_340",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_1hu00_350"
};
  