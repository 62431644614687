import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { Confirmation } from './Confirmation'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { Application } from './application/Application'
import { ApplicationDetail } from './application/ApplicationDetail'
import { ApplicationEdit } from './application/ApplicationEdit'
import { ApplicationList } from './application/ApplicationList'
import { CherokeeNationBenefits } from './benefits/CherokeeNationBenefits'
import { ChickasawNationBenefits } from './benefits/ChickasawNationBenefits'
import { ChildVerificationList } from './childVerification/ChildVerificationList'
import { AuditList } from './audit/AuditList'
import { Edit } from './application/Edit'
import { Home } from './home/Home'
import { Mio } from './mio/Mio'
import { PassiveEnrollmentDetail } from './passiveEnrollments/PassiveEnrollmentDetail'
import { PassiveEnrollmentEdit } from './passiveEnrollments/PassiveEnrollmentEdit'
import { PassiveEnrollmentList } from './passiveEnrollments/PassiveEnrollmentList'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { Settings } from './mio/Settings'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'
import { View } from './application/View'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [language, setLanguage] = useState('English')
  const [translations, setTranslations] = useState()
  const [missingTranslations, setMissingTranslations] = useState([])
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  useEffect(() => {
    const addMissingTranslation = async (text) => {
      console.log('Adding missing translation for', text)
      const response = await fetch('/api/translation/missing', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ language, text })
      })
      const translation = await response.text()
      if (response.status === 200) {
        console.log(translation)
        setTranslations({ ...translations, [text]: translation })
        setMissingTranslations(missingTranslations => missingTranslations.filter(x => x !== text))
      }
    }
    if (missingTranslations[0] != null) {
      addMissingTranslation(missingTranslations[0])
    }
  }, [missingTranslations])

  useEffect(() => {
    const getTranslations = async () => {
      console.log('Getting translations for', language)
      try {
        const response = await fetch(`/api/translation/${language}`)
        const json = await response.json()
        const object = json.reduce((acc, cur) => {
          acc[cur.text] = cur.translation
          return acc
        }, {})
        setTranslations(object)
      } catch (e) {
        console.error(e)
      }
    }
    if (language !== 'English') {
      getTranslations()
    }
  }, [language])

  function translate (text) {
    if (language === 'English') {
      return text
    }
    if (translations != null) {
      if (translations[text] == null && !missingTranslations.includes(text)) {
        setMissingTranslations(missingTranslations => [...missingTranslations, text])
      }
      return translations[text] ?? text
    }
    return text
  }

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  if (
    window.location.pathname.split('/')[1] !== 'admin'
  ) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          language,
          setLanguage,
          translate,
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal
        }}
        >
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/apply' element={<Application />} />
            <Route path='/cherokee-nation-benefits' element={<CherokeeNationBenefits />} />
            <Route path='/chickasaw-nation-benefits' element={<ChickasawNationBenefits />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path='/mio' element={<Mio />} />
            <Route path='/view/:uuid' element={<View />} />
            <Route path='/edit/:uuid' element={<Edit />} />
            <Route path='/admin' element={<ApplicationList />} />
            <Route path='*' element={<RadNotFound />} />
          </Routes>
          {loadingCount === 0 && notFound && <RadNotFound home='/' />}
          {permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user == null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        language,
        setLanguage,
        translate,
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/admin' element={<ApplicationList />} />

          <Route path='/admin/application' element={<ApplicationList />} />
          <Route path='/admin/application/:applicationId/edit' element={<ApplicationEdit />} />
          <Route path='/admin/application/:applicationId' element={<ApplicationDetail />} />

          <Route path='/admin/apply' element={<Application />} />

          <Route path='/admin/child-verification' element={<ChildVerificationList />} />
          <Route path='/admin/audit' element={<AuditList />} />

          <Route path='/admin/mio' element={<Mio />} />
          <Route path='/admin/mio/settings' element={<Settings />} />

          <Route path='/admin/passive-enrollment' element={<PassiveEnrollmentList />} />
          <Route path='/admin/passive-enrollment/:passiveEnrollmentId/edit' element={<PassiveEnrollmentEdit />} />
          <Route path='/admin/passive-enrollment/:passiveEnrollmentId' element={<PassiveEnrollmentDetail />} />

          <Route path='/admin/role' element={<RoleList />} />
          <Route path='/admin/role/create' element={<RoleEdit />} />
          <Route path='/admin/role/:roleId' element={<RoleDetail />} />
          <Route path='/admin/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/admin/confirmation' element={<Confirmation />} />

          <Route path='/admin/user' element={<UserList />} />
          <Route path='/admin/user/create' element={<UserEdit />} />
          <Route path='/admin/user/:userId' element={<UserDetail />} />
          <Route path='/admin/user/:userId/edit' element={<UserEdit />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound home='/admin' />}
        {permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
