
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_7xwti_193",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_7xwti_1",
  "root": "awsui_root_mx3cw_7xwti_215",
  "hidden": "awsui_hidden_mx3cw_7xwti_250",
  "with-dismiss": "awsui_with-dismiss_mx3cw_7xwti_297",
  "with-action": "awsui_with-action_mx3cw_7xwti_297",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_7xwti_303",
  "initial-hidden": "awsui_initial-hidden_mx3cw_7xwti_314",
  "header": "awsui_header_mx3cw_7xwti_319",
  "header-replacement": "awsui_header-replacement_mx3cw_7xwti_320",
  "action": "awsui_action_mx3cw_7xwti_324",
  "action-slot": "awsui_action-slot_mx3cw_7xwti_329",
  "action-button": "awsui_action-button_mx3cw_7xwti_330",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_7xwti_334",
  "text": "awsui_text_mx3cw_7xwti_363",
  "icon": "awsui_icon_mx3cw_7xwti_370",
  "message": "awsui_message_mx3cw_7xwti_373",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_7xwti_381",
  "icon-size-big": "awsui_icon-size-big_mx3cw_7xwti_384",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_7xwti_387",
  "content": "awsui_content_mx3cw_7xwti_391",
  "content-replacement": "awsui_content-replacement_mx3cw_7xwti_392",
  "dismiss": "awsui_dismiss_mx3cw_7xwti_396",
  "dismiss-button": "awsui_dismiss-button_mx3cw_7xwti_401",
  "type-error": "awsui_type-error_mx3cw_7xwti_405",
  "type-warning": "awsui_type-warning_mx3cw_7xwti_413",
  "type-success": "awsui_type-success_mx3cw_7xwti_421",
  "type-info": "awsui_type-info_mx3cw_7xwti_429"
};
  