
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_1u8gq_153",
  "button": "awsui_button_vjswe_1u8gq_157",
  "variant-normal": "awsui_variant-normal_vjswe_1u8gq_205",
  "variant-icon": "awsui_variant-icon_vjswe_1u8gq_248",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_1u8gq_248",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_1u8gq_248",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_1u8gq_269",
  "disabled": "awsui_disabled_vjswe_1u8gq_290",
  "variant-primary": "awsui_variant-primary_vjswe_1u8gq_297",
  "variant-link": "awsui_variant-link_vjswe_1u8gq_389",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_1u8gq_665",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_1u8gq_945",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_1u8gq_1042",
  "button-no-text": "awsui_button-no-text_vjswe_1u8gq_1134",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_1u8gq_1138",
  "full-width": "awsui_full-width_vjswe_1u8gq_1141",
  "icon-left": "awsui_icon-left_vjswe_1u8gq_1160",
  "icon-right": "awsui_icon-right_vjswe_1u8gq_1165",
  "icon": "awsui_icon_vjswe_1u8gq_1160",
  "link": "awsui_link_vjswe_1u8gq_1184",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_1u8gq_1184"
};
  