
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_1qd3m_161",
  "arrow": "awsui_arrow_18eso_1qd3m_161",
  "in-filtering-token-root": "awsui_in-filtering-token-root_18eso_1qd3m_221",
  "in-filtering-token-nested": "awsui_in-filtering-token-nested_18eso_1qd3m_221",
  "has-caret": "awsui_has-caret_18eso_1qd3m_255",
  "placeholder": "awsui_placeholder_18eso_1qd3m_258",
  "pressed": "awsui_pressed_18eso_1qd3m_271",
  "disabled": "awsui_disabled_18eso_1qd3m_274",
  "in-filtering-token": "awsui_in-filtering-token_18eso_1qd3m_221",
  "readonly": "awsui_readonly_18eso_1qd3m_290",
  "invalid": "awsui_invalid_18eso_1qd3m_312",
  "warning": "awsui_warning_18eso_1qd3m_321",
  "inline-tokens": "awsui_inline-tokens_18eso_1qd3m_330"
};
  