import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../App'
import { applicationsDisabled } from '../common/utilities'
import '../form.css'
import '../layout.css'
import '../responsive.css'

export function CherokeeNationBenefits () {
  const [isOpen, setIsOpen] = useState(false)
  const { language, setLanguage, translate } = useContext(AppContext)

  const links = (
    <>
      <li><Link to='/' role='button'>{translate('HOME')}</Link></li>
      {!applicationsDisabled() && <li><Link to='/apply' role='button'>{translate('APPLICATION')}</Link></li>}
      <li>{translate('CHEROKEE NATION BENEFITS')}</li>
      <li><Link to='/chickasaw-nation-benefits' role='button'>{translate('CHICKASAW NATION BENEFITS')}</Link></li>
      <li>
        <select
          onChange={(event) => {
            setLanguage(event.target.value)
            setIsOpen(false)
          }}
          id='language-menu'
          ariay-labelledby='language-menu-label'
          name='language-menu'
          value={language}
        >
          <option value='English'>{translate('ENGLISH')}</option>
          <option value='Spanish'>{translate('SPANISH')}</option>
        </select>
      </li>
    </>
  )

  return (
    <div>
      <section className='main_div main_menu_wrap menu_section_wrap'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 mnu_top text-center'>
              <ul className='web_nav'>
                {links}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className='responsive_header white-background' id='back_top_menu'>
        <div className='container-fluid px-lg-5'>
          <div className='row pding align-items-center'>
            <div className='col-8 ps-5' />
            <div className='mobile_icon_wrap col-4' onClick={() => setIsOpen(!isOpen)}>
              <div className='mobile_icon'>
                <i className='fas fa-bars' aria-hidden='true' />
              </div>
            </div>
          </div>
        </div>
        <ul id={isOpen ? 'open_nav' : null} className='top_menu responsive_header_nav pt-5 web_nav_wrap'>
          {links}
        </ul>
      </div>
      <div className='menu_holder' />

      <section className='main_div index_banner_wrap'>
        <div className='back_img_wrap'>
          <img src='assets/images/index_banner_converted.webp' alt='man holding small child' />
        </div>
        <div className='container banner_main_wrap'>
          <div className='row'>
            <div className='col-lg-8 ms-auto'>
              <div className='banner_content_wrap'>
                <h1>
                  {translate('CHEROKEE NATION')}
                  <br />
                  {translate('BENEFITS')}
                </h1>
                <p>{translate('Information about the items eligible for purchase with Summer EBT benefits and participating retailers can be found at the links below.')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='main_div index_benefits_wrap'>
        <div className='container-fluid pt-lg-5 px-lg-5'>
          <div className='row align-items-center px-lg-5'>
            <h2 style={{ whiteSpace: 'nowrap' }}>{translate('LINKS')}</h2>
            <br />
            <br />
            <br />
            <br />
            <p><a href='Cherokee - Summer EBT Welcome Document.pdf' target='_blank' rel='noreferrer' className='pdf'>{translate('Welcome Document')}</a></p>
            <p><a href='Cherokee - Summer EBT Helpful Hints.pdf' target='_blank' rel='noreferrer' className='pdf'>{translate('Helpful Hints')}</a></p>
            <p><a href='Cherokee - Summer EBT How to Shop.pdf' target='_blank' rel='noreferrer' className='pdf'>{translate('How to Shop')}</a></p>
            <p><a href='Cherokee - Summer EBT Lunch Meat.pdf' target='_blank' rel='noreferrer' className='pdf'>{translate('Lunch Meat')}</a></p>
            <p><a href='Cherokee - FY2022 SEBTC Food Card.pdf' target='_blank' rel='noreferrer' className='pdf'>{translate('Food Card')}</a></p>
            <h2>{translate('Approved Stores')}</h2>
            <Stores />
          </div>
        </div>
      </section>

      <footer className='main_div footer_wrap'>
        <div className='container-fluid px-lg-5'>
          <ul className='footer_web_nav'>
            {links}
          </ul>
          <div className='row '>
            <div className='col-lg-4 first_info_para'>
              <p>{translate('In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color, national origin, sex (including gender identity and sexual orientation), disability, age, or reprisal or retaliation for prior civil rights activity.  This institution is an equal opportunity provider')}.</p>
            </div>
            <div className='col-lg-4  contact_info_wrap'>
              <h3>{translate('Contact Info')}</h3>
              <div className='row justify-content-center '>
                <div className='col-lg-4 text-center px-0 mx-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0 mx-0'>

                  <p>{translate('For assistance or to apply over the phone please call the')} <b>{translate('Muscogee')}</b> {translate('Summer EBT Helpline')}</p>
                  <p><a href='tel:918-213-4855' target='_blank' rel='noreferrer'>918-213-4855 </a></p>
                  <p>{translate('9AM to 6PM Monday through Friday')}<br />{translate('or 9AM to 5PM Saturday')}.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 calling_wrap contact_info_wrap'>
              <div className='row '>
                <div className='col-lg-4 pe-0 text-center'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Cherokee Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p className=''><a href='tel:539-234-3265' role='button'>539-234-3265</a></p>
                </div>
                <div className='col-lg-4 text-center pe-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Chickasaw Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p><a href='tel:844-256-3467' role='button'>844-256-3467</a><span> or </span><a href='tel: 580-272-1178' role='button'>580-272-1178</a></p>
                </div>
              </div>
            </div>
            <p><a className='white' href='/admin' role='button'>{translate('Administrator Access')}</a></p>
          </div>
        </div>
      </footer>
    </div>
  )
}

function Stores () {
  return (
    <>
      <h3>Bartlesville</h3>
      <ul>
        <li>Walmart #41 - 4000 Green Country Rd. <a href='tel:918-335-6600'>918-335-6600</a></li>
      </ul>
      <h3>Bixby</h3>
      <ul>
        <li>Doc's Country Mart  - 15028 S. Memorial Dr. <a href='tel:918-366-8238'>918-366-8238</a></li>
      </ul>
      <h3>Broken Arrow</h3>
      <ul>
        <li>Reasor's #914 - 1100 E. Kenosha <a href='tel:918-251-5800'>918-251-5800</a></li>
        <li>Walmart #3295 - 6310 S. Elm Place <a href='tel:918-455-4354'>918-455-4354</a></li>
        <li>Walmart #472 - 2301 W. Kenosha <a href='tel:918-259-9126'>918-259-9126</a></li>
        <li>Walmart Neighborhood Market #3508 - 1770 S. Elm Place <a href='tel:918-258-6175'>918-258-6175</a></li>
      </ul>
      <h3>Catoosa</h3>
      <ul>
        <li>Walmart #3055 - 19801 Robson Rd.<a href='tel:918-739-7001'>918-739-7001</a></li>
        <li>Reasor's #917 - 2500 N. Hwy 66 <a href='tel:918-266-2161'>918-266-2161</a></li>
      </ul>
      <h3>Checotah</h3>
      <ul>
        <li>Walmart #340 - 131 Paul Carr Dr. <a href='tel:918-473-2201'>918-473-2201</a></li>
      </ul>
      <h3>Chelsea</h3>
      <ul>
        <li>Harps #181 - 300 E. Layton <a href='tel:918-789-2652'>918-789-2652</a></li>
      </ul>
      <h3>Chouteau</h3>
      <ul>
        <li>Harps #550 - 500 N. Choteau Ave. <a href='tel:918-476-6700'>918-476-6700</a></li>
      </ul>
      <h3>Claremore</h3>
      <ul>
        <li>Walmart #12 - 1500 S. Lynn Riggs Blvd. <a href='tel:918-341-2765'>918-341-2765</a></li>
        <li>Reasor's #904 - 1000 W. Will Rogers Blvd. <a href='tel:918-341-4036'>918-341-4036</a></li>
        <li>10 Box Cost Plus #388 - 322 N. Lynn Riggs Blvd. <a href='tel:918-341-1729'>918-341-1729</a></li>
      </ul>
      <h3>Coweta</h3>
      <ul>
        <li>Walmart #374 - 11207 S. State Hwy. 51 <a href='tel:918-394-4000'>918-394-4000</a></li>
        <li>Country Mart - 13937 OK-51 <a href='tel:918-486-3131'>918-486-3131</a></li>
      </ul>
      <h3>Fort Gibson</h3>
      <ul>
        <li>Harps #196 - 1206 S. Lee St. <a href='tel:918-478-2458'>918-478-2458</a></li>
      </ul>
      <h3>Glenpool</h3>
      <ul>
        <li>Walmart #4615 - 12200 S. Waco <a href='tel:918-299-8030'>918-299-8030</a></li>
        <li>Country Mart - 14189 S. Elm Street <a href='tel:918-322-3110'>918-322-3110</a></li>
      </ul>
      <h3>Gore</h3>
      <ul>
        <li>Harps #187  - 603 N. Main, Junction Hwy 10/100 <a href='tel:918-489-2202'>918-489-2202</a></li>
      </ul>
      <h3>Grove</h3>
      <ul>
        <li>Harps #165 - 1310 S. Main <a href='tel:918-786-4087'>918-786-4087</a></li>
        <li>Walmart #90 - 2115 S. Main <a href='tel:918-786-8561'>918-786-8561</a></li>
      </ul>
      <h3>Holdenville</h3>
      <ul>
        <li>Pruett's Food, Inc - 203 E. Highway St. <a href='tel:580-584-7433'>580-584-7433</a></li>
      </ul>
      <h3>Inola</h3>
      <ul>
        <li>Harps #152 - 30750 South Hwy. 88 <a href='tel:918-543-2140'>918-543-2140</a></li>
      </ul>
      <h3>Jay</h3>
      <ul>
        <li>Harps #169 - 1107 S. Main Street, Suite A <a href='tel:918-253-2011'>918-253-2011</a></li>
        <li>Homeland #853 - 1629 S. Main <a href='tel:918-253-4449'>918-253-4449</a></li>
      </ul>
      <h3>Langley</h3>
      <ul>
        <li>Reasor's #906 - 1624 N. 3rd St. <a href='tel:918-782-3227'>918-782-3227</a></li>
      </ul>
      <h3>Locust Grove</h3>
      <ul>
        <li>Harps #183 - Junction Hwy. 82/33 <a href='tel:918-479-8711'>918-479-8711</a></li>
        <li>Neighborhood Market #3723 - 800 E. Main <a href='tel:918-479-4810'>918-479-4810</a></li>
      </ul>
      <h3>Miami</h3>
      <ul>
        <li>Wal-Mart #28  - 2415 N. Main Street <a href='tel:918-542-6654'>918-542-6654</a></li>
      </ul>
      <h3>Muldrow</h3>
      <ul>
        <li>Piggly Wiggly #4396 - 1001 E. Shawntel Smith Blvd. <a href='tel:918-427-3741 '>918-427-3741 </a></li>
      </ul>
      <h3>Muskogee</h3>
      <ul>
        <li>10 Box Cost Plus #545 - 615 N. York St. <a href='tel:918-682-1194'>918-682-1194</a></li>
        <li>Cash Saver #880 - 3115 W. Okmulgee <a href='tel:918-687-6151'>918-687-6151</a></li>
        <li>Homeland #247 - 2410 Chandler Road <a href='tel:918-686-6730'>918-686-6730</a></li>
        <li>Homeland #881 - 1300 S. York <a href='tel:918-683-2854'>918-683-2854</a></li>
        <li>Walmart #130 - 1000 W. Shawnee <a href='tel:918-687-0058'>918-687-0058</a></li>
      </ul>
      <h3>Nowata</h3>
      <ul>
        <li>Homeland #856 - 813 E. Cherokee <a href='tel:918-273-2926'>918-273-2926</a></li>
      </ul>
      <h3>Okmulgee</h3>
      <ul>
        <li>Warehouse Market Cox Cash Saver #38  - 420 E. 8th Street <a href='tel:918-749-4732'>918-749-4732</a></li>
        <li>Walmart #121 - 1800 S. Wood Dr. <a href='tel:918-756-6790'>918-756-6790</a></li>
      </ul>
      <h3>Owasso</h3>
      <ul>
        <li>Reasor's #918 - 11550 N. 135th E. Ave. <a href='tel:918-371-6400'>918-371-6400</a></li>
        <li>Walmart #168 - 12101 E. 96th St. North <a href='tel:918-272-6609'>918-272-6609</a></li>
      </ul>
      <h3>Pryor</h3>
      <ul>
        <li>Homeland #539 - 925 S. Mill St. <a href='tel:918-825-5795'>918-825-5795</a></li>
        <li>Walmart #22 - 4901 S. Mill St. <a href='tel:918-825-6000'>918-825-6000</a></li>
      </ul>
      <h3>Roland</h3>
      <ul>
        <li>Harps #510 - 100 West Ray Fine Blvd. <a href='tel:918-427-6610'>918-427-6610</a></li>
      </ul>
      <h3>Salina </h3>
      <ul>
        <li>Harps #182 - 111 E. Ferry, Jct. Hwy 82/20 <a href='tel:918-434-2525'>918-434-2525</a></li>
      </ul>
      <h3>Sallisaw</h3>
      <ul>
        <li>10 Box Cost Plus #506  - 600 E. Cherokee <a href='tel:918-775-4418'>918-775-4418</a></li>
        <li>Walmart #47  - 1101 W. Ruth Ave. <a href='tel:918-775-4492'>918-775-4492</a></li>
      </ul>
      <h3>Sand Springs </h3>
      <ul>
        <li>Walmart #838 - 220 S. State Hwy. 97 <a href='tel:918-245-0213'>918-245-0213</a></li>
        <li>Cash Saver #595 - 250 S, OK-97 <a href='tel:918-245-6008'>918-245-6008</a></li>
      </ul>
      <h3>Sapulpa</h3>
      <ul>
        <li>Reasor's #903 - 132 W. Taft <a href='tel:918-224-9100'>918-224-9100</a></li>
        <li>Walmart #73 - 1002 W. Taft <a href='tel:918-224-8080'>918-224-8080</a></li>
      </ul>
      <h3>Seminole</h3>
      <ul>
        <li>Walmart #123 - 1500 E. Wrangler Blvd. <a href='tel:405-382-5290'>405-382-5290</a></li>
      </ul>
      <h3>Shawnee</h3>
      <ul>
        <li>Walmart #103 - 196 Shawnee Mall Dr. <a href='tel:405-275-1030'>405-275-1030</a></li>
      </ul>
      <h3>Siloam Springs</h3>
      <ul>
        <li>Harps #113 - 201 Hwy. 412 W. <a href='tel:479-524-4116'>479-524-4116</a></li>
        <li>Neighborhood Market #6960 - 935 S. Holly <a href='tel:479-549-9060'>479-549-9060</a></li>
        <li>Walmart #4 - 2901 Hwy. 412 E. <a href='tel:479-524-5101'>479-524-5101</a></li>
      </ul>
      <h3>Skiatook</h3>
      <ul>
        <li>Walmart #246 - 700 W. Rogers Blvd. <a href='tel:918-396-1244'>918-396-1244</a></li>
      </ul>
      <h3>Stilwell</h3>
      <ul>
        <li>Walmart #81 - 84127 Hwy. 59 <a href='tel:918-696-3141'>918-696-3141</a></li>
        <li>Harp's #505 - 419 N. 2nd St. <a href='tel:918-696-4722'>918-696-4722</a></li>
      </ul>
      <h3>Tahlequah </h3>
      <ul>
        <li>Reasor's #902 - 2001 S. Muskogee Ave. <a href='tel:918-456-2547'>918-456-2547</a></li>
        <li>Save A Lot  - 614 E. Downing St. <a href='tel:918-456-6971'>918-456-6971</a></li>
        <li>Walmart #10 - 2020 S. Muskogee Ave. <a href='tel:918-456-8804'>918-456-8804</a></li>
      </ul>
      <h3>Tulsa</h3>
      <ul>
        <li>Cox Cash Saver #19 - 6207 S. Peoria <a href='tel: 918-749-4732'>918-749-4732</a></li>
        <li>Oasis Fresh Market 1725 N Peoria Ave <a href='tel: 918-935-2092'>918-935-2092</a></li>
        <li>Reasor's #913 - 11005 E. 41st <a href='tel: 918-610-0610'>918-610-0610</a></li>
        <li>Reasor's #916 - 1885 S. Yale <a href='tel: 918-293-2244'>918-293-2244</a></li>
        <li>Reasor's #919 - 2429 E. 15th St. <a href='tel: 918-748-8332'>918-748-8332</a></li>
        <li>Walmart #1597 - 10938 S. Memorial <a href='tel: 918-394-4000'>918-394-4000</a></li>
        <li>Walmart #576 - 207 S. Memorial <a href='tel: 918-834-8700'>918-834-8700</a></li>
        <li>Walmart #894 - 6625 S. Memorial <a href='tel: 918-294-3800'>918-294-3800</a></li>
        <li>Walmart #992 - 2019 E. 81st St. <a href='tel: 918-488-8791'>918-488-8791</a></li>
        <li>Walmart Neighborhood Market #2395 - 9411 S. Delaware <a href='tel: 918-299-8316'>918-299-8316</a></li>
        <li>Warehouse Market #462 - 301 S. Lewis Ave. <a href='tel: 918-582-0622'>918-582-0622</a></li>
      </ul>
      <h3>Vian </h3>
      <ul>
        <li>Harps #546 - 108 Schley St. <a href='tel:918-773-5444'>918-773-5444</a></li>
      </ul>
      <h3>Vinta</h3>
      <ul>
        <li>Walmart #50 - 268 S. 7th St. <a href='tel:918-256-7505'>918-256-7505</a></li>
        <li>Carter's Food Center - 102 N. Scraper <a href='tel:918-256-5541'>918-256-5541</a></li>
      </ul>
      <h3>Wagoner</h3>
      <ul>
        <li>Walmart #63 - 410 S. Dewey <a href='tel:918-485-9515'>918-485-9515</a></li>
      </ul>
      <h3>Warner </h3>
      <ul>
        <li>Harps #544 - 206 N. Campbell St. <a href='tel:918-463-2699'>918-463-2699</a></li>
      </ul>
      <h3>Westville </h3>
      <ul>
        <li>Harps #191 - 70563 Hwy. 59 <a href='tel:918-723-3554'>918-723-3554</a></li>
      </ul>
    </>
  )
}
