
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_1srfh_145",
  "expanded": "awsui_expanded_m5h9f_1srfh_197",
  "offset-right-none": "awsui_offset-right-none_m5h9f_1srfh_208",
  "offset-right-l": "awsui_offset-right-l_m5h9f_1srfh_211",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_1srfh_214",
  "text": "awsui_text_m5h9f_1srfh_239",
  "icon": "awsui_icon_m5h9f_1srfh_243"
};
  