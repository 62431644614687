
    import './styles.scoped.css';
    export default {
  "top-navigation": "awsui_top-navigation_k5dlb_1s88y_145",
  "padding-box": "awsui_padding-box_k5dlb_1s88y_177",
  "medium": "awsui_medium_k5dlb_1s88y_187",
  "narrow": "awsui_narrow_k5dlb_1s88y_187",
  "virtual": "awsui_virtual_k5dlb_1s88y_198",
  "hidden": "awsui_hidden_k5dlb_1s88y_202",
  "\t": "awsui_\t_k5dlb_1s88y_1",
  "identity": "awsui_identity_k5dlb_1s88y_214",
  "identity-link": "awsui_identity-link_k5dlb_1s88y_217",
  "no-logo": "awsui_no-logo_k5dlb_1s88y_237",
  "logo": "awsui_logo_k5dlb_1s88y_241",
  "title": "awsui_title_k5dlb_1s88y_253",
  "inputs": "awsui_inputs_k5dlb_1s88y_268",
  "search": "awsui_search_k5dlb_1s88y_276",
  "search-expanded": "awsui_search-expanded_k5dlb_1s88y_280",
  "utilities": "awsui_utilities_k5dlb_1s88y_284",
  "utility-wrapper": "awsui_utility-wrapper_k5dlb_1s88y_295",
  "utility-type-button-link": "awsui_utility-type-button-link_k5dlb_1s88y_316",
  "utility-type-menu-dropdown": "awsui_utility-type-menu-dropdown_k5dlb_1s88y_321",
  "utility-type-button-primary-button": "awsui_utility-type-button-primary-button_k5dlb_1s88y_330",
  "utility-link-icon": "awsui_utility-link-icon_k5dlb_1s88y_338",
  "utility-button-external-icon": "awsui_utility-button-external-icon_k5dlb_1s88y_342",
  "offset-right-none": "awsui_offset-right-none_k5dlb_1s88y_346",
  "offset-right-l": "awsui_offset-right-l_k5dlb_1s88y_350",
  "offset-right-xxl": "awsui_offset-right-xxl_k5dlb_1s88y_354",
  "overflow-menu-drawer": "awsui_overflow-menu-drawer_k5dlb_1s88y_359",
  "overflow-menu": "awsui_overflow-menu_k5dlb_1s88y_359",
  "overflow-menu-header": "awsui_overflow-menu-header_k5dlb_1s88y_400",
  "overflow-menu-header-text": "awsui_overflow-menu-header-text_k5dlb_1s88y_408",
  "overflow-menu-header-text--secondary": "awsui_overflow-menu-header-text--secondary_k5dlb_1s88y_420",
  "overflow-menu-header-text--title": "awsui_overflow-menu-header-text--title_k5dlb_1s88y_426",
  "overflow-menu-back-button": "awsui_overflow-menu-back-button_k5dlb_1s88y_430",
  "overflow-menu-dismiss-button": "awsui_overflow-menu-dismiss-button_k5dlb_1s88y_434",
  "overflow-menu-control": "awsui_overflow-menu-control_k5dlb_1s88y_438",
  "overflow-menu-list-item-utility": "awsui_overflow-menu-list-item-utility_k5dlb_1s88y_476",
  "overflow-menu-control-link": "awsui_overflow-menu-control-link_k5dlb_1s88y_484",
  "overflow-menu-control-expandable-menu-trigger": "awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1s88y_487",
  "overflow-menu-list": "awsui_overflow-menu-list_k5dlb_1s88y_476",
  "overflow-menu-list-submenu": "awsui_overflow-menu-list-submenu_k5dlb_1s88y_523",
  "overflow-menu-list-item": "awsui_overflow-menu-list-item_k5dlb_1s88y_476",
  "overflow-menu-list-item-icon": "awsui_overflow-menu-list-item-icon_k5dlb_1s88y_532",
  "overflow-menu-list-item-text": "awsui_overflow-menu-list-item-text_k5dlb_1s88y_535",
  "overflow-menu-list-item-submenu": "awsui_overflow-menu-list-item-submenu_k5dlb_1s88y_544",
  "overflow-menu-list-item-dropdown-menu": "awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1s88y_550",
  "overflow-menu-list-item-expandable": "awsui_overflow-menu-list-item-expandable_k5dlb_1s88y_554",
  "icon": "awsui_icon_k5dlb_1s88y_559",
  "icon-open": "awsui_icon-open_k5dlb_1s88y_573"
};
  