
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_ah6ur_181",
  "refresh": "awsui_refresh_2qdw9_ah6ur_217",
  "root-no-actions": "awsui_root-no-actions_2qdw9_ah6ur_217",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_ah6ur_220",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_ah6ur_220",
  "root-has-description": "awsui_root-has-description_2qdw9_ah6ur_220",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_ah6ur_229",
  "main": "awsui_main_2qdw9_ah6ur_239",
  "no-wrap": "awsui_no-wrap_2qdw9_ah6ur_249",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_ah6ur_255",
  "actions": "awsui_actions_2qdw9_ah6ur_263",
  "actions-centered": "awsui_actions-centered_2qdw9_ah6ur_268",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_ah6ur_271",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_ah6ur_275",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_ah6ur_279",
  "title": "awsui_title_2qdw9_ah6ur_295",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_ah6ur_300",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_ah6ur_307",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_ah6ur_313",
  "virtual-space": "awsui_virtual-space_2qdw9_ah6ur_334",
  "info": "awsui_info_2qdw9_ah6ur_340",
  "description": "awsui_description_2qdw9_ah6ur_344",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_ah6ur_351",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_ah6ur_355",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_ah6ur_362",
  "heading": "awsui_heading_2qdw9_ah6ur_371",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_ah6ur_382",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_ah6ur_387",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_ah6ur_392",
  "heading-text": "awsui_heading-text_2qdw9_ah6ur_398",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_ah6ur_401",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_ah6ur_409",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_ah6ur_417",
  "counter": "awsui_counter_2qdw9_ah6ur_426"
};
  